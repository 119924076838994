import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';
import { NumberField, TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import CheckboxField from '../../apiForm/form/input/CheckboxField';

export default function VoucherProvidersForm({
  defaultValues = {},
  errors = {},
  onSubmit,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.voucherProviders')}
      >
        <ArrayWrapper
          control={control}
          name="voucherProviders"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.voucherProviders.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.voucherProviders.remove')}
          render={(index: number) => (
            <Stack spacing={2}>
              <TextField
                control={control}
                name={`voucherProviders.[${index}].id`}
                label={t('checkoutConfig.label.voucherProviders.id')}
                helperText={t('checkoutConfig.helperText.voucherProviders.id')}
              />
              <TextField
                control={control}
                name={`voucherProviders.[${index}].name`}
                label={t('checkoutConfig.label.voucherProviders.name')}
              />
              <TextField
                control={control}
                name={`voucherProviders.[${index}].serviceCredentialID`}
                optional
                label={t('checkoutConfig.label.voucherProviders.serviceCredentialID')}
                helperText={t('checkoutConfig.helperText.voucherProviders.serviceCredentialID')}
              />
              <TextField
                control={control}
                name={`voucherProviders.[${index}].companyID`}
                label={t('checkoutConfig.label.voucherProviders.companyID')}
                helperText={t('checkoutConfig.helperText.voucherProviders.companyID')}
              />
              <CheckboxField
                control={control}
                name={`voucherProviders.[${index}].multipleRedemptionsAllowed`}
                label={t('checkoutConfig.label.voucherProviders.multipleRedemptionsAllowed')}
              />
              <NumberField
                control={control}
                name={`voucherProviders.[${index}].minTotalThreshold`}
                optional
                label={t('checkoutConfig.label.voucherProviders.minTotalThreshold')}
                helperText={t('checkoutConfig.helperText.voucherProviders.minTotalThreshold')}
                rules={{ min: 0 }}
                sx={{ marginBottom: 2 }}
              />
              <ArrayWrapper
                control={control}
                name={`voucherProviders.[${index}].templates`}
                defaultValue={{}}
                addLabel={t('checkoutConfig.arrayWrapper.voucherTemplate.add')}
                removeLabel={t('checkoutConfig.arrayWrapper.voucherTemplate.remove')}
                inlineDeleteButton
                indent
                render={(index2: number) => (
                  <Stack spacing={1} direction="row" flexGrow={1} sx={{ '> div': { flexGrow: 1 } }}>
                    <TextField
                      control={control}
                      name={`voucherProviders.[${index}].templates.[${index2}].template`}
                      label={t('checkoutConfig.label.voucherProviders.template')}
                      fullWidth
                    />
                  </Stack>
                )}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
        noCancelNavigation
        reset={reset}
        getValues={getValues}
      />
    </Form>
  );
}
