import {
  ClosingLogSinks,
  LogFormat,
  SinkConfiguration,
  TransactionConfig,
} from '../project/transactionConfig';

export type ClosingLogSinksArray = Array<{
  logFormat: LogFormat;
  sinkConfiguration: SinkConfiguration;
}>;

export type TransactionConfigIntermediate =
  (
    Omit<TransactionConfig, 'closingLogSinks'>
  ) & {
    closingLogSinksArray: ClosingLogSinksArray;
  };

// the closing log sinks config is a key value object, with the log format as the key
// and the config as the value. However to dynamically add and remove configs with the form
// it has to be an array of configs. So the object is converted to an array to be edited and
// converted back to an object once submitted.
export function closingLogSinksToArray(
  closingLogSinks: ClosingLogSinks | undefined,
): ClosingLogSinksArray {
  if (!closingLogSinks) return [];
  return Object
    .entries(closingLogSinks)
    .map(([key, value]) => ({ logFormat: key as LogFormat, sinkConfiguration: value }));
}

export function toClosingLogSinks(
  closingLogSinksArray: ClosingLogSinksArray | undefined,
): ClosingLogSinks {
  if (!closingLogSinksArray) return {};
  return Object.fromEntries(
    closingLogSinksArray.map(({ logFormat, sinkConfiguration }) => (
      [logFormat, sinkConfiguration]
    )),
  );
}
