import React from 'react';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SelectField, TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function FlowForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.defaultFlow')}
        sublegend={t('checkoutConfig.sublegend.defaultFlow')}
      >
        <CheckboxField
          control={control}
          name="defaultFlow.supervisorApprovalRequired"
          disabled={readonly}
          label={t('checkoutConfig.label.flow.supervisorApprovalRequired')}
        />
        <SelectField
          control={control}
          name="defaultFlow.supervisorApprovalMethod"
          disabled={readonly}
          label={t('checkoutConfig.label.flow.supervisorApprovalMethod')}
          helperText={t('checkoutConfig.helperText.flow.supervisorApprovalMethod')}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="control">Control</MenuItem>
          <MenuItem value="partialRescan">Partial Rescan</MenuItem>
          <MenuItem value="rescan">Rescan</MenuItem>
          <MenuItem value="gatekeeeper">Gatekeeeper</MenuItem>
        </SelectField>
        <SelectField
          control={control}
          name="defaultFlow.supervisorApprovalMethodYellow"
          disabled={readonly}
          label={t('checkoutConfig.label.flow.supervisorApprovalMethodYellow')}
          helperText={t('checkoutConfig.helperText.flow.supervisorApprovalMethodYellow')}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="control">Control</MenuItem>
          <MenuItem value="partialRescan">Partial Rescan</MenuItem>
          <MenuItem value="rescan">Rescan</MenuItem>
          <MenuItem value="gatekeeeper">Gatekeeeper</MenuItem>
        </SelectField>
        <SelectField
          control={control}
          name="defaultFlow.supervisorApprovalMethodRed"
          disabled={readonly}
          label={t('checkoutConfig.label.flow.supervisorApprovalMethodRed')}
          helperText={t('checkoutConfig.helperText.flow.supervisorApprovalMethodRed')}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="control">Control</MenuItem>
          <MenuItem value="partialRescan">Partial Rescan</MenuItem>
          <MenuItem value="rescan">Rescan</MenuItem>
          <MenuItem value="gatekeeeper">Gatekeeeper</MenuItem>
        </SelectField>
        <SelectField
          control={control}
          name="defaultFlow.ageVerificationMode"
          disabled={readonly}
          label={t('checkoutConfig.label.flow.ageVerificationMode')}
          helperText={t('checkoutConfig.helperText.flow.ageVerificationMode')}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="inPaymentProcess">In Payment Process</MenuItem>
          <MenuItem value="supervisor">Supervisor</MenuItem>
        </SelectField>
        <CheckboxField
          control={control}
          name="defaultFlow.ageVerificationIgnoreStoredAge"
          disabled={readonly}
          label={`${t('checkoutConfig.label.flow.ageVerificationIgnoreStoredAge')} (${t('checkoutConfig.helperText.flow.ageVerificationIgnoreStoredAge')})`}
        />
        <TextField
          control={control}
          name="defaultFlow.exitTokenProvider"
          disabled={readonly}
          label={t('checkoutConfig.label.flow.exitTokenProvider')}
          helperText={t('checkoutConfig.helperText.flow.exitTokenProvider')}
        />
        <TextField
          control={control}
          name="defaultFlow.exitTokenConfiguration.secret"
          disabled={readonly}
          label={t('checkoutConfig.label.flow.exitTokenConfigurationSecret')}
        />
        <CheckboxField
          control={control}
          name="defaultFlow.userVerificationRequired"
          disabled={readonly}
          label={`${t('checkoutConfig.label.flow.userVerificationRequired')} (${t('checkoutConfig.helperText.flow.userVerificationRequired')})`}
        />
        <CheckboxField
          control={control}
          name="defaultFlow.grabAndGoEnabled"
          disabled={readonly}
          label={`${t('checkoutConfig.label.flow.grabAndGoEnabled')} (${t('checkoutConfig.helperText.flow.grabAndGoEnabled')})`}
        />
        <CheckboxField
          control={control}
          name="defaultFlow.askForLocation"
          disabled={readonly}
          label={`${t('checkoutConfig.label.flow.askForLocation')} (${t('checkoutConfig.helperText.flow.askForLocation')})`}
        />
      </ExpandableFieldset>

      <ExpandableFieldset
        legend={t('checkoutConfig.legend.flows')}
        sublegend={t('checkoutConfig.sublegend.flows')}
      >
        <ArrayWrapper
          control={control}
          name="flows"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.flow.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.flow.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`flows.[${index}].shopID`}
                disabled={readonly}
                optional
                label={t('checkoutConfig.label.flow.shopID')}
              />
              <CheckboxField
                control={control}
                name={`flows.[${index}].supervisorApprovalRequired`}
                disabled={readonly}
                label={t('checkoutConfig.label.flow.supervisorApprovalRequired')}
              />
              <SelectField
                control={control}
                name={`flows.[${index}].supervisorApprovalMethod`}
                disabled={readonly}
                label={t('checkoutConfig.label.flow.supervisorApprovalMethod')}
                helperText={t('checkoutConfig.helperText.flow.supervisorApprovalMethod')}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="control">Control</MenuItem>
                <MenuItem value="partialRescan">Partial Rescan</MenuItem>
                <MenuItem value="rescan">Rescan</MenuItem>
                <MenuItem value="gatekeeeper">Gatekeeeper</MenuItem>
              </SelectField>
              <SelectField
                control={control}
                name={`flows.[${index}].supervisorApprovalMethodYellow`}
                disabled={readonly}
                label={t('checkoutConfig.label.flow.supervisorApprovalMethodYellow')}
                helperText={t('checkoutConfig.helperText.flow.supervisorApprovalMethodYellow')}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="control">Control</MenuItem>
                <MenuItem value="partialRescan">Partial Rescan</MenuItem>
                <MenuItem value="rescan">Rescan</MenuItem>
                <MenuItem value="gatekeeeper">Gatekeeeper</MenuItem>
              </SelectField>
              <SelectField
                control={control}
                name={`flows.[${index}].supervisorApprovalMethodRed`}
                disabled={readonly}
                label={t('checkoutConfig.label.flow.supervisorApprovalMethodRed')}
                helperText={t('checkoutConfig.helperText.flow.supervisorApprovalMethodRed')}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="control">Control</MenuItem>
                <MenuItem value="partialRescan">Partial Rescan</MenuItem>
                <MenuItem value="rescan">Rescan</MenuItem>
                <MenuItem value="gatekeeeper">Gatekeeeper</MenuItem>
              </SelectField>
              <SelectField
                control={control}
                name={`flows.[${index}].ageVerificationMode`}
                disabled={readonly}
                label={t('checkoutConfig.label.flow.ageVerificationMode')}
                helperText={t('checkoutConfig.helperText.flow.ageVerificationMode')}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="inPaymentProcess">In Payment Process</MenuItem>
                <MenuItem value="supervisor">Supervisor</MenuItem>
              </SelectField>
              <CheckboxField
                control={control}
                name={`flows.[${index}].ageVerificationIgnoreStoredAge`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.flow.ageVerificationIgnoreStoredAge')} (${t('checkoutConfig.helperText.flow.ageVerificationIgnoreStoredAge')})`}
              />
              <TextField
                control={control}
                name={`flows.[${index}].exitTokenProvider`}
                disabled={readonly}
                label={t('checkoutConfig.label.flow.exitTokenProvider')}
                helperText={t('checkoutConfig.helperText.flow.exitTokenProvider')}
              />
              <TextField
                control={control}
                name={`flows.[${index}].exitTokenConfiguration.secret`}
                disabled={readonly}
                label={t('checkoutConfig.label.flow.exitTokenConfigurationSecret')}
              />
              <CheckboxField
                control={control}
                name={`flows.[${index}].userVerificationRequired`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.flow.userVerificationRequired')} (${t('checkoutConfig.helperText.flow.userVerificationRequired')})`}
              />
              <CheckboxField
                control={control}
                name={`flows.[${index}].grabAndGoEnabled`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.flow.grabAndGoEnabled')} (${t('checkoutConfig.helperText.flow.grabAndGoEnabled')})`}
              />
              <CheckboxField
                control={control}
                name={`flows.[${index}].askForLocation`}
                disabled={readonly}
                label={`${t('checkoutConfig.label.flow.askForLocation')} (${t('checkoutConfig.helperText.flow.askForLocation')})`}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
        noCancelNavigation
        reset={reset}
        getValues={getValues}
      />
    </Form>
  );
}
