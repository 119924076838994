import React, { ReactNode, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  FieldValues,
  FormState,
  UseFormGetValues,
  UseFormReset,
} from 'react-hook-form';
import { CancelButton, SubmitButton } from './button';
import { SubmitButtonFormState } from './button/SubmitButton';
import { CancelButtonFormState } from './button/CancelButton';
import FormActions from './FormActions';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
  },
}));

export interface DefaultFormActionsProps {
  additionalButtons?: ReactNode;
  formState: SubmitButtonFormState
  & CancelButtonFormState
  & Pick<FormState<FieldValues>, 'isSubmitSuccessful'>
  cancelNavigationTarget?: string;
  noCancelNavigation?: boolean;
  // NOTE provide getValues and reset to reset form after successful submit
  // these values are optional for backward compatibility
  getValues?: UseFormGetValues<FieldValues>;
  reset?: UseFormReset<FieldValues>;
}

export default function DefaultFormActions({
  additionalButtons,
  formState,
  cancelNavigationTarget,
  noCancelNavigation,
  getValues,
  reset,
}: DefaultFormActionsProps) {
  const { isSubmitSuccessful } = formState;
  useEffect(() => {
    if (!getValues || !reset) return;
    if (isSubmitSuccessful) reset(getValues());
  }, [getValues, isSubmitSuccessful, reset]);

  return (
    <Container>
      <FormActions allowReverse>
        <SubmitButton formState={formState} />
        <CancelButton
          formState={formState}
          href={cancelNavigationTarget}
          onConfirm={noCancelNavigation ? reset : undefined}
        />
      </FormActions>

      {additionalButtons && (
        <FormActions>
          {additionalButtons}
        </FormActions>
      )}
    </Container>
  );
}
