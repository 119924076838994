import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control, FieldValues, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { SinkType } from '../../project/transactionConfig';
import { SelectOptionsField, TextField } from '../../apiForm/form/input';

interface SinkFieldProps {
  namePrefix: string,
  control: Control,
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  includeEmpty?: boolean,
  onEmptySelected?: () => void,
}

// wrapper for the materialization sink settings so they can be dynamically shown
// this also resets hidden fields to null
export default function SinkField({
  namePrefix,
  control,
  watch,
  setValue,
  includeEmpty = false,
  onEmptySelected,
}: SinkFieldProps) {
  const { t } = useTranslation();

  const selectedSinkType = watch(`${namePrefix}.type`);

  useEffect(() => {
    if (selectedSinkType === SinkType.RESTService) {
      setValue(`${namePrefix}.action`, null);
      return;
    }
    if (selectedSinkType === SinkType.SoapService) {
      setValue(`${namePrefix}.tokenUrl`, null);
      return;
    }
    if (selectedSinkType === SinkType.Storage) {
      setValue(`${namePrefix}.endpoint`, null);
      setValue(`${namePrefix}.username`, null);
      setValue(`${namePrefix}.password`, null);
      setValue(`${namePrefix}.tokenUrl`, null);
      setValue(`${namePrefix}.action`, null);
      return;
    }
    onEmptySelected?.();
  }, [selectedSinkType, setValue, namePrefix, onEmptySelected]);

  return (
    <>
      <SelectOptionsField
        control={control}
        name={`${namePrefix}.type`}
        options={Object.values(SinkType)}
        valueFrom={(value: any) => value}
        labelFrom={(value: any) => t(`transactionConfig.materialization.sinkType.${value}`)}
        label={t('transactionConfig.materialization.input.type')}
        includeEmpty={includeEmpty}
        emptyLabel="Disabled"
      />
      <Stack
        spacing={1}
        sx={{
          width: '100%',
          paddingLeft: '32px',
          borderLeft: '1.5px solid grey',
        }}
      >
        {[SinkType.RESTService, SinkType.SoapService].includes(selectedSinkType) && (
          <>
            <TextField
              control={control}
              name={`${namePrefix}.endpoint`}
              label={t('transactionConfig.materialization.input.endpoint')}
            />
            <TextField
              control={control}
              name={`${namePrefix}.username`}
              label={t('transactionConfig.materialization.input.username')}
            />
            <TextField
              control={control}
              name={`${namePrefix}.password`}
              label={t('transactionConfig.materialization.input.password')}
            />
          </>
        )}
        {selectedSinkType === SinkType.RESTService && (
          <TextField
            control={control}
            name={`${namePrefix}.tokenUrl`}
            label={t('transactionConfig.materialization.input.tokenUrl')}
          />
        )}
        {selectedSinkType === SinkType.SoapService && (
          <TextField
            control={control}
            name={`${namePrefix}.action`}
            label={t('transactionConfig.materialization.input.action')}
          />
        )}
      </Stack>
    </>
  );
}
