import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import CleanupScheduleView from './CleanupScheduleView';
import MaterializationView from './MaterializationView';
import ClosingLogSinkView from './ClosingLogSinkView';

export default function TransactionRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="materialization" replace />} />
      <Route path="/materialization" element={<MaterializationView />} />
      <Route path="/cleanup-schedule" element={<CleanupScheduleView />} />
      <Route path="/closing-log-sink" element={<ClosingLogSinkView />} />
    </Routes>
  );
}
