import { FieldValues } from 'react-hook-form';
import {
  defineApi,
  useProjectSpecificApiClient,
} from '../apiForm/api';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
import { TransactionConfig } from './transactionConfig';
import removeUndefinedObjectEntries, { repairObject } from '../utils/apiObjectUtils';
import { closingLogSinksToArray, toClosingLogSinks, TransactionConfigIntermediate } from '../utils/closingLogSinkUtils';

const sanitizeData = (data: FieldValues) => {
  const repairedObject = repairObject(data);

  delete repairedObject.closingLogSinksArray;

  let cleanedData: FieldValues = {
    ...repairedObject,
  };

  cleanedData = removeUndefinedObjectEntries(cleanedData);

  return cleanedData;
};

const useApi = defineApi({
  getTransactionConfig: async (client) => {
    const { status, data } = await client<TransactionConfig>({
      url: '',
      method: 'GET',
      validateStatus: (httpCode) => isSuccessfulOrCustomResponse(httpCode, [404]),
    });
    if (status === 404) return {} as TransactionConfigIntermediate;
    const formData: TransactionConfigIntermediate = {
      ...data,
      closingLogSinksArray: closingLogSinksToArray(data.closingLogSinks),
    };
    return formData;
  },
  updateTransactionConfig: (client, formData: TransactionConfigIntermediate) => {
    const data = {
      ...formData,
      closingLogSinks: toClosingLogSinks(formData.closingLogSinksArray),
    };
    const cleanedData = sanitizeData(data);
    return client.put('', cleanedData);
  },
});

export default function useTransactionConfigApi() {
  const client = useProjectSpecificApiClient({ basePath: '/orders/config' });
  return useApi(client);
}
