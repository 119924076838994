import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import update from 'lodash/update';
import { safeCommaSeparatedStringToArray } from './apiStringUtils';

export function repairObject(incomingObject: any) {
  if (isArray(incomingObject)) {
    let currentObject = structuredClone(incomingObject);
    currentObject = currentObject.filter((i: any) => i !== undefined);
    currentObject = currentObject.map((o) => (repairObject(o)));
    return currentObject;
  }
  if (isPlainObject(incomingObject)) {
    const currentObject = structuredClone(incomingObject);
    Object.keys(currentObject).forEach((key) => {
      currentObject[key] = repairObject(currentObject[key]);
    });
    return currentObject;
  }
  return incomingObject;
}

export default function removeUndefinedObjectEntries(objectParam: object) {
  const currentObject = Object.assign(objectParam);
  Object.keys(currentObject).forEach((i) => {
    if (currentObject[i] === undefined) delete currentObject[i];
    if (typeof currentObject[i] === 'object' && currentObject[i]) currentObject[i] = removeUndefinedObjectEntries(currentObject[i]);
  });
  return currentObject;
}

export function parseAllCommaSeparatedStringsInObjectToArray(object: any, wildcardedPath: string) {
  const paths = wildcardedPath.split('.[*].');
  const path = paths[0];
  const rest = paths.slice(1);

  if (!rest.length) {
    return update(object, path, (n) => safeCommaSeparatedStringToArray(n));
  }

  get(object, path)?.map((x: any) => parseAllCommaSeparatedStringsInObjectToArray(x, rest.join('.[*].')));
  return object;
}

export function updateObjectAtPath(object: any, wildcardedPath: string, modifier: (x: any) => any) {
  const paths = wildcardedPath.split('.[*].');
  const path = paths[0];
  const rest = paths.slice(1);

  if (!rest.length) {
    return update(object, path, (n) => modifier(n));
  }

  get(object, path)?.map((x: any) => updateObjectAtPath(x, rest.join('.[*].'), modifier));
  return object;
}
