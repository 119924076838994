import React from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import SinkField from './SinkField';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import { LogFormat } from '../../project/transactionConfig';
import SearchableFreeSoloSelectField from '../../apiForm/form/input/SearchableFreeSoloSelectField';

const DEFAULT_VALUES: any = {};

interface ClosingLogSinkFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
}

export default function ClosingLogSinkForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: ClosingLogSinkFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset
        category="closingLogSink"
        legend={t('transactionConfig.closingLogSink.fieldset.sink')}
      >
        <ArrayWrapper
          control={control}
          name="closingLogSinksArray"
          defaultValue={{}}
          render={(index: number) => (
            <Stack spacing={1} sx={{ width: '100%' }}>
              <SearchableFreeSoloSelectField
                control={control}
                name={`closingLogSinksArray[${index}].logFormat`}
                options={Object.values(LogFormat)}
                label={t('transactionConfig.closingLogSink.input.logFormat')}
              />
              <SinkField
                namePrefix={`closingLogSinksArray[${index}].sinkConfiguration`}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            </Stack>
          )}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
        noCancelNavigation
        reset={reset}
        getValues={getValues}
      />
    </Form>
  );
}
