import React from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { matchSorter } from 'match-sorter';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import { MaterializationFormat, TransactionKind } from '../../project/transactionConfig';
import SinkField from './SinkField';
import SearchableChipSelectField from '../../apiForm/form/input/SearchableChipSelectField';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import SearchableFreeSoloSelectField from '../../apiForm/form/input/SearchableFreeSoloSelectField';

const servicesSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'id' },
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'title' },
  ],
};

const DEFAULT_VALUES: any = {};

interface MaterializationFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
}

export default function MaterializationForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: MaterializationFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset
        category="general"
        legend={t('transactionConfig.materialization.fieldset.general')}
      >
        <CheckboxField
          control={control}
          name="includeNonFinalOrders"
          label={t('transactionConfig.materialization.input.includeNonFinalOrders')}
        />
      </Fieldset>

      <Fieldset
        category="materialization"
        legend={t('transactionConfig.materialization.fieldset.materializations')}
      >
        <ArrayWrapper
          control={control}
          name="materializations"
          defaultValue={{}}
          render={(index: number) => (
            <Stack spacing={1} sx={{ width: '100%' }}>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                <SearchableFreeSoloSelectField
                  control={control}
                  name={`materializations[${index}].format`}
                  options={Object.values(MaterializationFormat)}
                  label={t('transactionConfig.materialization.input.format')}
                  fullWidth
                />
                <SearchableChipSelectField
                  name={`materializations[${index}].kinds`}
                  control={control}
                  options={Object.entries(TransactionKind).map(([name, value]) => (
                    { title: name, id: value }
                  ))}
                  sorterOptions={servicesSorterOptions}
                  label={t('transactionConfig.materialization.input.kinds')}
                />
              </div>
              <SinkField
                namePrefix={`materializations[${index}].sink`}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            </Stack>
          )}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
        noCancelNavigation
        reset={reset}
        getValues={getValues}
      />
    </Form>
  );
}
