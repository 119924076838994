import React, { ReactNode, useCallback } from 'react';
import { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import { useController } from 'react-hook-form';
import { MatchSorterOptions } from 'match-sorter';
import { FieldProps } from './FieldProps';
import useFormLabel from './useFormLabel';
import ValidationError from './ValidationError';
import SearchableChipSelect from '../../../form/SearchableChipSelect';

const createEvent = (value: any) => ({ target: { value } });

export interface SearchableChipSelectFieldProps<T> extends FieldProps<MUITextFieldProps> {
  options: T[];
  sorterOptions: MatchSorterOptions;
  labelFrom?: keyof T | ((option: T | undefined) => ReactNode);
  valueFrom?: keyof T;
  optional?: boolean;
}

export default function SearchableChipSelectField<T extends Record<string, any>>({
  options,
  sorterOptions,
  labelFrom = 'title',
  valueFrom = 'id',
  control,
  name,
  rules,
  type,
  optional = false,
  ...props
}: SearchableChipSelectFieldProps<T>) {
  const label = useFormLabel(name, props.label, { removeIndex: true, optionalHint: optional });

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules });

  const handleAddService = useCallback((id: any) => {
    onChange(createEvent([...(value || []), id]));
  }, [onChange, value]);

  const handleRemoveService = useCallback((id: any) => {
    onChange(createEvent(value.filter((s: T) => s !== id)));
  }, [onChange, value]);

  const handleClearService = useCallback(() => {
    onChange(createEvent([]));
  }, [onChange]);

  return (
    <SearchableChipSelect
      options={options}
      sorterOptions={sorterOptions}
      onAdd={handleAddService}
      onRemove={handleRemoveService}
      onClear={handleClearService}
      labelFrom={labelFrom}
      valueFrom={valueFrom}
      type={type}
      label={label as any}
      variant="outlined"
      value={value || []}
      error={!!error}
      helperText={error && <ValidationError error={error} />}
      fullWidth
      {...props}
    />
  );
}
