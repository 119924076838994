import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DeleteIcon from '@mui/icons-material/Delete';
import { FieldValues } from 'react-hook-form';
import { matchSorter } from 'match-sorter';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SelectField, TextField } from '../../apiForm/form/input';
import { ResourceTranslate } from '../../apiForm/resource';
import {
  NewRealm,
  PasswordChoicePolicy,
  PasswordValidationPolicyTypes,
  ProjectRole,
  RealmUpdate,
  SubValidationPolicyTypes,
} from '../../realm/useRealmApi';
import SearchableSelectField from '../../apiForm/form/input/SearchableSelectField';
import { ProjectConfig } from '../../project/useProjectApi';

const DEFAULT_VALUES: any = {
};

const searchableProjectSelectSorterOptions = {
  keys: [
    { threshold: matchSorter.rankings.WORD_STARTS_WITH, key: 'title' }, // TODO name
    { threshold: matchSorter.rankings.ACRONYM, key: 'id' },
  ],
};

interface RealmFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
  createNewRealm?: boolean;
  availableProjects: ProjectConfig[];
  onRealmDelete?: () => Promise<void>;
}

export default function RealmForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
  createNewRealm,
  availableProjects,
  onRealmDelete,
}: RealmFormProps<NewRealm | RealmUpdate>) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const confirmDeleteRealm = useCallback(async () => {
    setDeleteDialogOpen(false);
    await onRealmDelete?.();
  }, [onRealmDelete]);

  return (
    <>
      <Dialog open={deleteDialogOpen} maxWidth="xs">
        <DialogTitle>
          {t('realmView.deleteRealm.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('realmView.deleteRealm.confirmationBody')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setDeleteDialogOpen(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={confirmDeleteRealm} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset category="general">
          {createNewRealm && (
            <TextField
              control={control}
              name="id"
              disabled={readonly}
              required
            />
          )}
          <TextField
            control={control}
            name="name"
            disabled={readonly}
            required
          />
        </Fieldset>

        <Fieldset category="subValidationPolicy">
          <SelectField
            control={control}
            name="subValidationPolicy.type"
            required
            rules={{ required: true }}
          >
            {Object.values(SubValidationPolicyTypes).map((type) => (
              <MenuItem value={type}>
                <ResourceTranslate id={`realmView.validationPolicy.${type}`} />
              </MenuItem>
            ))}
          </SelectField>
          <TextField
            control={control}
            name="subValidationPolicy.minLength"
            disabled={readonly}
            optional
          />
          <TextField
            control={control}
            name="subValidationPolicy.maxLength"
            disabled={readonly}
            optional
          />
        </Fieldset>

        <Fieldset category="passwordValidationPolicy">
          <SelectField
            control={control}
            name="passwordValidationPolicy.type"
            required
            rules={{ required: true }}
          >
            {Object.values(PasswordValidationPolicyTypes).map((type) => (
              <MenuItem value={type}>
                <ResourceTranslate id={`realmView.validationPolicy.${type}`} />
              </MenuItem>
            ))}
          </SelectField>
          <TextField
            control={control}
            name="passwordValidationPolicy.minLength"
            disabled={readonly}
            optional
          />
          <TextField
            control={control}
            name="passwordValidationPolicy.maxLength"
            disabled={readonly}
            optional
          />
        </Fieldset>

        <Fieldset category="passwordChoicePolicy">
          <SelectField
            control={control}
            name="passwordChoicePolicy"
            required
            rules={{ required: true }}
          >
            {Object.values(PasswordChoicePolicy).map((policy) => (
              <MenuItem value={policy}>
                <ResourceTranslate id={`realmView.passwordChoicePolicy.${policy}`} />
              </MenuItem>
            ))}
          </SelectField>
        </Fieldset>

        <Fieldset category="allowedRoles">
          <SelectField
            control={control}
            name="allowedRoles"
            multiple
            optional
            helperText={t('helperText.emptyForAll')}
          >
            {Object.values(ProjectRole).map((role) => (
              <MenuItem value={role}>
                <ResourceTranslate id={`realmView.allowedRoles.${role}`} />
              </MenuItem>
            ))}
          </SelectField>
        </Fieldset>

        <Fieldset category="allowedProjects">
          <SearchableSelectField
            control={control}
            name="allowedProjects"
            sorterOptions={searchableProjectSelectSorterOptions}
            multiple
            optional
            options={availableProjects}
            labelFrom="name"
            valueFrom="id"
            helperText={t('helperText.emptyForAll')}
          />
        </Fieldset>

        <DefaultFormActions
          formState={formState}
          cancelNavigationTarget="/realms"
          additionalButtons={(
            <Button
              aria-label="delete"
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => { setDeleteDialogOpen(true); }}
              disabled={!onRealmDelete}
            >
              {t('button.remove')}
            </Button>
          )}
        />
      </Form>
    </>
  );
}
