import React, { useCallback } from 'react';
import { FieldValue } from 'react-hook-form';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import useTransactionConfigApi from '../../project/useTransactionConfigApi';
import MaterializationForm from './MaterializationForm';

export default function MaterializationView() {
  const api = useTransactionConfigApi();

  const fetch = useCallback(async () => {
    const config = await api.getTransactionConfig(null);
    return config;
  }, [api]);

  const onSubmit = useCallback(async (data: FieldValue<any>) => {
    await api.updateTransactionConfig(data);
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="transactionConfig.materialization.headline" />
      <div>
        <ResourceFormView
          actionName="materialization"
          Form={MaterializationForm}
          fetch={fetch}
          submit={onSubmit}
        />
      </div>
    </>
  );
}
