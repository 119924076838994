import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';
import { TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';

export default function DepositReturnVoucherProvidersForm({
  defaultValues = {},
  errors = {},
  onSubmit,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.depositReturnVoucherProviders')}
      >
        <ArrayWrapper
          control={control}
          name="depositReturnVoucherProviders"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.depositReturnVoucherProviders.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.depositReturnVoucherProviders.remove')}
          render={(index: number) => (
            <Stack spacing={2}>
              <TextField
                control={control}
                name={`depositReturnVoucherProviders.[${index}].id`}
                label={t('checkoutConfig.label.depositReturnVoucherProviders.id')}
                helperText={t('checkoutConfig.helperText.depositReturnVoucherProviders.id')}
              />
              <TextField
                control={control}
                name={`depositReturnVoucherProviders.[${index}].name`}
                label={t('checkoutConfig.label.depositReturnVoucherProviders.name')}
                helperText={t('checkoutConfig.helperText.depositReturnVoucherProviders.name')}
              />
              <TextField
                control={control}
                name={`depositReturnVoucherProviders.[${index}].token`}
                label={t('checkoutConfig.label.depositReturnVoucherProviders.token')}
                helperText={t('checkoutConfig.helperText.depositReturnVoucherProviders.token')}
              />
              <ArrayWrapper
                control={control}
                name={`depositReturnVoucherProviders.[${index}].templates`}
                defaultValue={{}}
                addLabel={t('checkoutConfig.arrayWrapper.depositReturnVoucherTemplate.add')}
                removeLabel={t('checkoutConfig.arrayWrapper.depositReturnVoucherTemplate.remove')}
                inlineDeleteButton
                indent
                render={(index2: number) => (
                  <Stack spacing={1} direction="row" flexGrow={1} sx={{ '> div': { flexGrow: 1 } }}>
                    <TextField
                      control={control}
                      name={`depositReturnVoucherProviders.[${index}].templates.[${index2}].template`}
                      label={t('checkoutConfig.label.depositReturnVoucherProviders.template')}
                      fullWidth
                    />
                  </Stack>
                )}
              />
              <ArrayWrapper
                control={control}
                name={`depositReturnVoucherProviders.[${index}].mappings`}
                defaultValue={{}}
                addLabel={t('checkoutConfig.arrayWrapper.depositReturnVoucherMappings.add')}
                removeLabel={t('checkoutConfig.arrayWrapper.depositReturnVoucherMappings.remove')}
                inlineDeleteButton
                indent
                render={(index2: number) => (
                  <Stack spacing={1} direction="row" flexGrow={1} sx={{ '> div': { flexGrow: 1 } }}>
                    <TextField
                      control={control}
                      name={`depositReturnVoucherProviders.[${index}].mappings.[${index2}].key`}
                      label={t('checkoutConfig.label.depositReturnVoucherProviders.mappingKey')}
                      fullWidth
                    />
                    <TextField
                      control={control}
                      name={`depositReturnVoucherProviders.[${index}].mappings.[${index2}].value`}
                      label={t('checkoutConfig.label.depositReturnVoucherProviders.mappingValue')}
                      fullWidth
                    />
                  </Stack>
                )}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
        noCancelNavigation
        reset={reset}
        getValues={getValues}
      />
    </Form>
  );
}
