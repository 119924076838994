interface CleanupSchedule {
  enabled: boolean;
  staleDays: number;
}

export enum MaterializationFormat {
  CSV = 'csv',
  MORAWA_SK = 'csv/morawa',
  JSON = 'json',
  JSON_BOB = 'json/bob',
  ENFORE = 'json/enfore',
  JSON_TCHIBO = 'json/tchibo',
  POS_LOG_HEINEMANN = 'poslog/heinemann',
  POS_LOG_STOREWEAVER = 'poslog/storeweaver',
  SCALEDATA_TEEGSCHWENDNER = 'scaledata/teegschwendner',
}

export enum SinkType {
  Storage = 'storage',
  RESTService = 'rest',
  SoapService = 'soap',
}

export interface SinkConfiguration {
  type: SinkType;
  endpoint?: string; // rest, soap
  action?: string; // soap
  username?: string; // rest, soap
  password?: string; // rest, soap
  tokenUrl?: string; // rest
}

export enum TransactionKind {
  Sale = 'sale',
  Closing = 'closing',
}

interface MaterializationConfiguration {
  format: MaterializationFormat;
  sink: SinkConfiguration;
  kinds?: TransactionKind[];
}

export enum LogFormat {
  AGGREGATED_RECEIPT = 'aggregated_receipts',
  CSV_ALDI = 'csv/aldi-sued',
  CSV = 'csv/transactions',
  TCHIBO = 'day_end/tchibo',
  TEGUT = 'daily_totals/tegut',
  DFKA_TAXONOMIE = 'dfka_taxonomie_2023_03',
  DSFINVK = 'dsfinvk_2023_03',
  POSLOG_STOREWEAVER = 'poslog/storeweaver',
  POSLOG_TEGUT = 'poslog/tegut',
  RECEIPT_ARCHIVE = 'receipt_archive',
  SK_MORAWA = 'sk/morawa',
  SRS_EGECKO = 'srs/egecko',
  VTK_SOLD = 'vtksold/morawa',
}

export type ClosingLogSinks = Partial<Record<LogFormat, SinkConfiguration>>;

export interface TransactionConfig {
  id: string;
  materializations: MaterializationConfiguration[];
  closingLogSinks?: ClosingLogSinks;
  includeNonFinalOrders: boolean;
  cleanupSchedule: CleanupSchedule;
}
