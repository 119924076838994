import React, { ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FieldContainer from './FieldContainer';
import { FieldProps } from './FieldProps';
import useFormLabel from './useFormLabel';
import ValidationError from './ValidationError';

export interface SearchableSelectFieldProps<T> extends FieldProps<any> {
  options: T[];
  label?: string | undefined;
  helperText?: ReactNode;
  optional?: boolean;
}

// This component is similar the SearchableSelectField but allows the user to enter custom strings
// Since the freeSolo option of the MUI Autocomplete component is very picky about everything this
// is a new component that has a lot less features and only takes in arrays of values and does not
// support label and value getters.
export default function SearchableFreeSoloSelectField<T>({
  control,
  name,
  options,
  helperText,
  rules,
  optional,
  ...props
}: SearchableSelectFieldProps<T>) {
  const label = useFormLabel(name, props.label, { optionalHint: optional });

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error} fullWidth>
            <Autocomplete
              options={options}
              renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
              onChange={(_, data) => onChange(data)}
              onInputChange={(_, data) => onChange(data)}
              value={value}
              label={label}
              freeSolo
              autoSelect
              {...props}
            />

            {error && (
              <FormHelperText>
                <ValidationError error={error} />
              </FormHelperText>
            )}

            {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
          </FormControl>
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
