import React, { useEffect } from 'react';
import {
  Control, FieldValues, UseFormGetValues, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {
  CheckoutFormProps, DefaultFormActions, Form, useEnhancedForm,
} from '../../apiForm/form';
import { NumberField, SelectField, TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';
import { PaymentMethodID } from '../../project/CheckoutConfig';

interface PaymentMethodSelectProps {
  name: string,
  control: Control,
  disabled: boolean,
}

function PaymentMethodSelect({ name, control, disabled }: PaymentMethodSelectProps) {
  const { t } = useTranslation();

  return (
    <SelectField
      control={control}
      name={name}
      disabled={disabled}
      label={t('checkoutConfig.label.paymentConfig.methodID')}
    >
      {Object.values(PaymentMethodID).map((methodID) => (
        <MenuItem value={methodID} key={`${name}.${methodID}`}>
          {methodID}
        </MenuItem>
      ))}
    </SelectField>
  );
}

interface NestedFieldProps {
  namePrefix: string,
  control: Control,
  disabled: boolean,
}

function PaymentServiceProviderSelect(
  { namePrefix, control, disabled }: NestedFieldProps,
) {
  const { t } = useTranslation();

  return (
    <SelectField
      name={`${namePrefix}.providerName`}
      control={control}
      disabled={disabled}
      label={t('checkoutConfig.label.paymentConfig.providerName')}
    >
      <MenuItem value="telecash">Telecash</MenuItem>
      <MenuItem value="datatrans">Datatrans</MenuItem>
      <MenuItem value="payone">Payone</MenuItem>
      <MenuItem value="external-billing">snabble ExternalBilling</MenuItem>
    </SelectField>
  );
}

function BaseURIField({ namePrefix, control, disabled }: NestedFieldProps) {
  const { t } = useTranslation();

  return (
    <TextField
      control={control}
      name={`${namePrefix}.baseURI`}
      disabled={disabled}
      label={t('checkoutConfig.label.paymentConfig.baseURI')}
      helperText={t('checkoutConfig.helperText.paymentConfig.baseURI')}
    />
  );
}

function PaymentServiceFields({ namePrefix, control, disabled }: NestedFieldProps) {
  return (
    <>
      <BaseURIField
        control={control}
        namePrefix={namePrefix}
        disabled={disabled}
      />
      <PaymentServiceProviderSelect
        control={control}
        namePrefix={namePrefix}
        disabled={disabled}
      />
    </>
  );
}

interface ValidationPolicyFieldsProps extends NestedFieldProps {
  heading: string,
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
}

function ValidationPolicyFields(
  {
    namePrefix, control, disabled, heading, watch, setValue,
  }: ValidationPolicyFieldsProps,
) {
  const { t } = useTranslation();

  const validationType = watch(`${namePrefix}.type`);
  useEffect(() => {
    if (!validationType) {
      setValue(namePrefix, null);
    }
  }, [namePrefix, setValue, validationType]);

  return (
    <>
      <Typography variant="overline">
        {heading}
      </Typography>
      <SelectField
        control={control}
        name={`${namePrefix}.type`}
        disabled={disabled}
        label={t('checkoutConfig.label.paymentConfig.validationPolicy.type')}
        defaultValue={null}
      >
        <MenuItem value={null}>NONE</MenuItem>
        <MenuItem value="any">ANY</MenuItem>
        <MenuItem value="numeric">NUMERIC</MenuItem>
      </SelectField>
      <NumberField
        control={control}
        name={`${namePrefix}.length`}
        disabled={disabled || !validationType}
        label={t('checkoutConfig.label.paymentConfig.validationPolicy.length')}
        rules={{ pattern: /^[0-9]+$/ }}
      />
      <Divider />
    </>
  );
}

function isMerchantInitiatedOnlinePayment(methodID: string): boolean {
  return [
    PaymentMethodID.APPLE_PAY,
    PaymentMethodID.AUTONOMO_ONLINE_PAYMENT,
    PaymentMethodID.CREDIT_CARD_AMERICAN_EXPRESS,
    PaymentMethodID.CREDIT_CARD_MASTERCARD,
    PaymentMethodID.CREDIT_CARD_VISA,
    PaymentMethodID.POST_FINANCE_CARD,
    PaymentMethodID.TEST_SEPA,
    PaymentMethodID.TWINT,
  ].find((m) => m === methodID) != null;
}

function PaymentMethodSpecificElements({
  namePrefix, control, disabled, watch, setValue, getValues,
}: PaymentMethodDetailFormProps) {
  const { t } = useTranslation();

  const methodID = watch(`${namePrefix}.methodID`);

  // Default allowRefunds to true for gatekeeperTerminal or unset it for other payment methods
  useEffect(() => {
    if (methodID === PaymentMethodID.GATEKEEPER_TERMINAL) {
      if (getValues(`${namePrefix}.allowRefunds`) == null) {
        setValue(`${namePrefix}.allowRefunds`, true);
      }
    } else {
      setValue(`${namePrefix}.allowRefunds`, null);
    }
  }, [methodID, namePrefix, setValue, getValues]);

  if (isMerchantInitiatedOnlinePayment(methodID)) {
    return (
      <Stack spacing={1}>
        <PaymentServiceFields
          control={control}
          namePrefix={namePrefix}
          disabled={disabled}
        />
        <TextField
          control={control}
          name={`${namePrefix}.acceptedOriginTypes`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.acceptedOriginTypes')} (comma separated)`}
          helperText={t('checkoutConfig.helperText.paymentConfig.acceptedOriginTypes')}
        />
      </Stack>
    );
  }

  if (methodID === PaymentMethodID.DE_DIRECT_DEBIT) {
    return (
      <Stack spacing={1}>
        <PaymentServiceFields
          control={control}
          namePrefix={namePrefix}
          disabled={disabled}
        />
        <TextField
          control={control}
          name={`${namePrefix}.acceptedOriginTypes`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.acceptedOriginTypes')} (comma separated)`}
          helperText={t('checkoutConfig.helperText.paymentConfig.acceptedOriginTypes')}
        />
        <CheckboxField
          control={control}
          name={`${namePrefix}.manageSepaMandateByProvider`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.manageSepaMandateByProvider')}`}
        />
        <CheckboxField
          control={control}
          name={`${namePrefix}.originVerificationRequired`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.originVerificationRequired')} (${t('checkoutConfig.helperText.paymentConfig.originVerificationRequired')})`}
        />
      </Stack>
    );
  }

  if (methodID === PaymentMethodID.GOOGLE_PAY) {
    return (
      <Stack spacing={1}>
        <PaymentServiceFields
          control={control}
          namePrefix={namePrefix}
          disabled={disabled}
        />
        <CheckboxField
          control={control}
          name={`${namePrefix}.isTesting`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.isTesting')} (${t('checkoutConfig.helperText.paymentConfig.isTesting')})`}
        />
      </Stack>
    );
  }

  if (methodID === PaymentMethodID.EXTERNAL_BILLING
    || methodID === PaymentMethodID.GATEKEEPER_EXTERNAL_BILLING) {
    return (
      <Stack spacing={1}>
        <TextField
          control={control}
          name={`${namePrefix}.methodName`}
          disabled={disabled}
          label={t('checkoutConfig.label.paymentConfig.methodName')}
          helperText={t('checkoutConfig.helperText.paymentConfig.methodName')}
        />
        <PaymentServiceProviderSelect
          control={control}
          namePrefix={namePrefix}
          disabled={disabled}
        />
        <BaseURIField
          control={control}
          namePrefix={namePrefix}
          disabled={disabled}
        />
        <TextField
          control={control}
          name={`${namePrefix}.acceptedOriginTypes`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.acceptedOriginTypes')} (comma separated)`}
          helperText={t('checkoutConfig.helperText.paymentConfig.acceptedOriginTypes')}
        />
        <Divider />
        <ValidationPolicyFields
          heading={t('checkoutConfig.label.paymentConfig.customerIDValidationPolicy')}
          namePrefix={`${namePrefix}.customerIDValidationPolicy`}
          control={control}
          disabled={disabled}
          watch={watch}
          setValue={setValue}
        />
        <ValidationPolicyFields
          heading={t('checkoutConfig.label.paymentConfig.passwordValidationPolicy')}
          namePrefix={`${namePrefix}.passwordValidationPolicy`}
          control={control}
          disabled={disabled}
          watch={watch}
          setValue={setValue}
        />
      </Stack>
    );
  }

  if (methodID === PaymentMethodID.VOUCHER) {
    return (
      <TextField
        control={control}
        name={`${namePrefix}.providerName`}
        disabled={disabled}
        label={t('checkoutConfig.label.paymentConfig.providerName')}
      />
    );
  }

  if (methodID === PaymentMethodID.GATEKEEPER_TERMINAL) {
    return (
      <CheckboxField
        control={control}
        name={`${namePrefix}.allowRefunds`}
        disabled={disabled}
        label={`${t('checkoutConfig.label.paymentConfig.allowRefunds')}`}
      />
    );
  }

  return null;
}

interface PaymentMethodDetailFormProps {
  namePrefix: string,
  control: Control,
  disabled: boolean,
  watch: UseFormWatch<FieldValues>,
  setValue: UseFormSetValue<FieldValues>,
  getValues: UseFormGetValues<FieldValues>,
}

function PaymentMethodDetailsForm(
  {
    namePrefix, control, disabled, watch, setValue, getValues,
  }: PaymentMethodDetailFormProps,
) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <PaymentMethodSelect
        control={control}
        disabled={disabled}
        name={`${namePrefix}.methodID`}
      />

      <PaymentMethodSpecificElements
        control={control}
        disabled={disabled}
        namePrefix={namePrefix}
        watch={watch}
        setValue={setValue}
        getValues={getValues}
      />

      <Stack spacing={1}>
        <NumberField
          control={control}
          name={`${namePrefix}.limit`}
          disabled={disabled}
          optional
          label={t('checkoutConfig.label.paymentConfig.limit')}
          helperText={t('checkoutConfig.helperText.paymentConfig.limit')}
          rules={{ pattern: /^[0-9]+$/ }}
        />
        <CheckboxField
          control={control}
          name={`${namePrefix}.triggerReceiptGeneration`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.triggerReceiptGeneration')} (${t('checkoutConfig.helperText.paymentConfig.triggerReceiptGeneration')})`}
        />
        <CheckboxField
          control={control}
          name={`${namePrefix}.verifiesAge`}
          disabled={disabled}
          label={`${t('checkoutConfig.label.paymentConfig.verifiesAge')} (${t('checkoutConfig.helperText.paymentConfig.verifiesAge')})`}
        />
      </Stack>
    </Stack>
  );
}

export default function PaymentConfigurationForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly = false,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    reset,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.defaultPaymentConfigurations')}
        sublegend={t('checkoutConfig.sublegend.defaultPaymentConfigurations')}
      >
        <ArrayWrapper
          control={control}
          name="defaultPaymentConfigurations"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.paymentConfig.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.paymentConfig.remove')}
          render={(index: number) => (
            <PaymentMethodDetailsForm
              namePrefix={`defaultPaymentConfigurations.[${index}]`}
              control={control}
              disabled={readonly}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
            />
          )}
        />
      </ExpandableFieldset>

      <ExpandableFieldset
        legend={t('checkoutConfig.legend.paymentConfigurations')}
        sublegend={t('checkoutConfig.sublegend.paymentConfigurations')}
      >
        <ArrayWrapper
          control={control}
          name="paymentConfigurations"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.shopConfig.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.shopConfig.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`paymentConfigurations.[${index}].shopID`}
                disabled={readonly}
                label={t('checkoutConfig.label.paymentConfig.shopID')}
              />
              <ArrayWrapper
                control={control}
                name={`paymentConfigurations.[${index}].configurations`}
                defaultValue={{}}
                addLabel={t('checkoutConfig.arrayWrapper.paymentConfig.add')}
                removeLabel={t('checkoutConfig.arrayWrapper.paymentConfig.remove')}
                indent
                render={(index2: number) => (
                  <PaymentMethodDetailsForm
                    namePrefix={`paymentConfigurations.[${index}].configurations.[${index2}]`}
                    control={control}
                    disabled={readonly}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                  />
                )}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>
      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
        noCancelNavigation
        reset={reset}
        getValues={getValues}
      />
    </Form>
  );
}
